import React from 'react';
import {
  Button,
  Image,
} from 'semantic-ui-react';


const ResultRenderer = ({
  title, image, 'data-custom-handler': isCustomHandler,
}) => {
  if (isCustomHandler) {
    return (
      <Button fluid className="search-not-found">
        {title}
      </Button>
    );
  }

  const item = [
    image && (
      <div key="image" className="image">
        <Image src={image} size="small" />
      </div>
    ),
    <div key="content" className="content">
      {title && <div className="title">{title}</div>}
    </div>,
  ];

  return item;
};

export default ResultRenderer;
