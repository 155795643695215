import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button, Image, Form, Grid, Header, Loader,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { OrderedMap } from 'immutable';
import whiteImage from '../../img/white-image.png';
import Upload from '../helpers/components/Upload';
import Textarea from '../helpers/components/Textarea';
import QuantityField from '../helpers/components/QuantityField';
import QualityLevel from './QualityLevel';
import Stepper from './Stepper';

const ProductAdd = ({
  selectedProduct: product,
  setSelectedProduct,
  history: { push },
  match: { params },
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = React.useState(OrderedMap());

  React.useEffect(() => {
    axios.get(`/api/v1/quality-levels/?product=${product.id}`).then(({ data: qualityLevels }) => {
      setSelectedProduct((state) => ({ ...state, quality_levels: qualityLevels }));
    });
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  if (product.quality_levels === undefined) {
    return <Loader active inline="centered" />;
  }

  const hasQualityLevel = (
    product && product.quality_level && Object.keys(product.quality_level).length
  );

  const handleSubmit = () => {
    const payload = {
      quotation: parseInt(params.id, 10),
      product: product.id,
      quality_level: product.quality_level.id,
      quantity: product.quantity,
      comment: product.comment,
      images: files.valueSeq().toArray().filter((file) => file.uploaded).map((file) => file.id),
    };
    axios.post('/api/v1/quotation-items/', payload)
      .then(() => push(`/quotation/${params.id}/checkout`));
  };

  const handleComment = (value) => {
    setSelectedProduct((state) => ({ ...state, comment: value }));
  };

  const handleQuantity = (value) => {
    setSelectedProduct((state) => ({ ...state, quantity: value }));
  };

  return (
    <>
      <Stepper step={2} />
      <Header as="h3">{t('Round 2')}</Header>
      <Header as="h2">{t('Which item would you like to sell to us?')}</Header>
      <Form onSubmit={handleSubmit}>
        <Grid padded fluid>
          <Grid.Row>
            <Grid.Column mobile={6} tablet={8} computer={8}>
              <Image size="medium" src={product.image || whiteImage} />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" mobile={10} tablet={8} computer={8}>
              <Header as="h2">{ product.name }</Header>
              <Form.Field>
                <label className="quantity-button-label" htmlFor="#quantity-input">{t('Quantity')}</label>
                <QuantityField
                  id="quantity-input"
                  value={product.quantity}
                  onChange={handleQuantity}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <QualityLevel
                setSelectedProduct={setSelectedProduct}
                items={product.quality_levels}
              />

              <Textarea
                label={t('Do you want to insert a comment? (optional)')}
                name="comment"
                text={product.comment}
                onChange={handleComment}
              />

              <Upload
                label={t('Do you want to send a photo? (optional)')}
                quotation={params.id}
                files={files}
                setFiles={setFiles}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={2} stackable reversed="computer tablet" className="buttons-pair">
          <Grid.Column textAlign="right">
            <Button
              type="submit"
              id="detail-add"
              content={t('Next stage')}
              primary
              fluid
              disabled={!hasQualityLevel}
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              id="detail-close"
              content={t('Back')}
              type="button"
              as="button"
              fluid
              onClick={() => push(`/quotation/${params.id}`)}
            />
          </Grid.Column>
        </Grid>
      </Form>
    </>
  );
};

ProductAdd.propTypes = {
  selectedProduct: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
};

export default withRouter(ProductAdd);
