import React from 'react';
import path from 'path';
import { withRouter } from 'react-router-dom';

const HelpButton = ({
  history: { push },
  location: { pathname },
  children,
}) => {
  const handleTriggerClick = () => {
    push(path.join(pathname, '/help'));
  };

  if (!children) {
    return (
      <button
        className="help-button"
        type="button"
        onClick={handleTriggerClick}
      >
        <span>Dúvidas de como funciona?</span>
      </button>
    );
  }

  return (
    <button
      type="button"
      className="link"
      onClick={handleTriggerClick}
    >
      {children}
    </button>
  );
};

export default withRouter(HelpButton);
