import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import QuotationForm from './QuotationForm';

const QuotationCreate = ({ history: { push } }) => {
  const submitForm = (values, actions) => (
    axios.post('/api/v1/quotations/', values)
      .then(({ data }) => {
        actions.setSubmitting(false);
        push(`/quotation/${data.id}`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          actions.setSubmitting(false);
          actions.setErrors({ ...err.response.data });
        }
      })
  );

  return (
    <QuotationForm
      initialValues={{
        name: '', email: '', phone_number: '', client_origin: '', city: '',
      }}
      onSubmit={submitForm}
    />
  );
};
export default withRouter(QuotationCreate);
