import React from 'react';
import { Formik, Field as FormikField } from 'formik';
import {
  Form, Grid, Button, Header,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import getMessages from '../helpers/messages';
import { useClientOrigins } from '../helpers/hooks';
import InputField from '../helpers/components/InputField';
import SelectField from '../helpers/components/SelectField';
import CitySearch from './CitySearch';
import Stepper from './Stepper';

const PersonalInfoSchema = (messages) => Yup.object().shape({
  name: Yup.string()
    .min(4, messages.FIELD_MIN_LENGTH(4))
    .required(messages.FIELD_REQUIRED),
  email: Yup.string()
    .email(messages.EMAIL_INVALID)
    .required(messages.FIELD_REQUIRED),
  phone_number: Yup.string()
    .required(messages.FIELD_REQUIRED),
  city: Yup.string()
    .required(messages.CITY_REQUIRED),
  client_origin: Yup.string()
    .required(messages.FIELD_REQUIRED),
});

const QuotationForm = ({
  initialValues,
  onSubmit: handleSubmit,
}) => {
  const { t } = useTranslation();
  const { clientOrigins } = useClientOrigins();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PersonalInfoSchema(getMessages(t))}
      onSubmit={handleSubmit}
      render={({ errors, isSubmitting, handleSubmit: formikHandleSubmit }) => (
        <Form
          onSubmit={formikHandleSubmit}
          loading={isSubmitting}
          error={!!Object.keys(errors).length}
        >
          <Stepper step={1} />

          <Header as="h3">{t('Round 1')}</Header>

          <Header as="h2">
            {t('personalInfoInstructions', 'We need some informations to evaluate your items. Complete the fields bellow:')}
          </Header>

          <FormikField
            component={InputField}
            name="name"
            label={t('Full name')}
            placeholder={t('You name here')}
          />

          <FormikField
            component={InputField}
            name="email"
            label={t('Email')}
            placeholder={t('email@example.com')}
          />

          <FormikField
            component={InputField}
            control={InputMask}
            name="phone_number"
            label={t('Mobile number')}
            mask="(99) 99999-9999"
            alwaysShowMask
          />

          <FormikField
            component={CitySearch}
            name="city"
            label={t('City')}
            placeholder={t('Start typing to search')}
          />

          <FormikField
            component={SelectField}
            name="client_origin"
            label={t('How did you hear about MeuGameUsado?')}
            options={clientOrigins}
          />

          <Grid className="buttons-pair" columns={2} stackable reversed="computer tablet">
            <Grid.Column textAlign="right">
              <Button
                id="next"
                type="submit"
                content={t('Next stage')}
                primary
                fluid
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                id="prev"
                type="button"
                content={t('Back')}
                secondary
                fluid
                disabled
              />
            </Grid.Column>
          </Grid>
        </Form>
      )}
    />
  );
};

export default QuotationForm;
