import React from 'react';
import {
  Header,
  Form,
  Segment,
  Grid,
  Button,
  Loader,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useProductsAndQuotation } from '../helpers/hooks';
import SearchField from './SearchField';
import Stepper from './Stepper';

const ProductSearch = ({
  setSelectedProduct,
  match: { params },
  history: { push },
}) => {
  const { t } = useTranslation();
  const { products } = useProductsAndQuotation(params.id);

  if (products === undefined) {
    return <Loader active inline="centered" />;
  }

  return (
    <>
      <Stepper step={2} />

      <Header as="h3">Round 2</Header>
      <Header as="h2">Qual item usado você gostaria de nos vender?</Header>

      <Form>
        <SearchField setSelectedProduct={setSelectedProduct} />
      </Form>

      <Header
        as="h4"
        attached="top"
        className="tips"
      >
        {t('Tips to find better results')}
      </Header>

      <Segment className="tips" attached="bottom">
        <p>
          {t(
            'tipsInstructions',
            'We buy several second-hand products, games, acessories, smartphones and more! If you have some product that you believe that make sense for the MeuGameUsado universe, like action figures, fell free to include them in your quotation',
          )}
        </p>

        <dl>
          <dt>{t('Example of a well-formatted game search')}</dt>
          <dd className="correct">{t('LEGO City Undercover game - Xbox One')}</dd>

          <dt>{t('Example of poorly formatted search for games')}</dt>
          <dd className="wrong">{t('LEGO Xbox')}</dd>

          <dt>{t('Example of a well formatted search for consoles')}</dt>
          <dd className="correct">{t('Console Playstation 4 Slim 500GB - Sony')}</dd>

          <dt>{t('Example of poorly formatted search for consoles')}</dt>
          <dd className="wrong">{t('Videogame')}</dd>
        </dl>
      </Segment>

      <Grid className="buttons-pair" columns={2} stackable reversed="computer tablet">
        <Grid.Column textAlign="right">
          <Button
            id="next"
            type="button"
            content={t('Next stage')}
            disabled={products.size === 0}
            primary
            fluid
            onClick={() => push(`/quotation/${params.id}/checkout`)}
          />
        </Grid.Column>
        <Grid.Column>
          <Button
            id="prev"
            type="button"
            content={t('Back')}
            secondary
            fluid
            onClick={() => push(`/quotation/${params.id}/update`)}
          />
        </Grid.Column>
      </Grid>
    </>
  );
};

export default withRouter(ProductSearch);
