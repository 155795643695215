import React from 'react';
import { render } from 'react-dom';

import axiosDefaults from 'axios/lib/defaults';
import App from './components/App';

import './i18n';

axiosDefaults.xsrfCookieName = 'csrftoken';
axiosDefaults.xsrfHeaderName = 'X-CSRFToken';

const container = document.getElementById('app-root');
const googleAnalyticsId = container.dataset.googleanalyticsid;

render(<App googleAnalyticsId={googleAnalyticsId} />, container);
