import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { useProductsAndQuotation } from '../helpers/hooks';
import QuotationForm from './QuotationForm';

const QuotationUpdate = ({
  match: { params: { id: quotationId } },
  history: { push },
}) => {
  const { quotation } = useProductsAndQuotation(quotationId);

  if (quotation === undefined) {
    return <Loader active inline="centered" />;
  }

  const submitForm = (values, actions) => (
    axios.put(`/api/v1/quotations/${quotationId}/`, values)
      .then(({ data }) => {
        actions.setSubmitting(false);
        push(`/quotation/${data.id}`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          actions.setSubmitting(false);
          actions.setErrors({ ...err.response.data });
        }
      })
  );

  return (
    <QuotationForm
      initialValues={{
        name: quotation.name,
        email: quotation.email,
        phone_number: quotation.phone_number,
        client_origin: quotation.client_origin,
        city: quotation.city,
      }}
      onSubmit={submitForm}
    />
  );
};
export default withRouter(QuotationUpdate);
