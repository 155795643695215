import React from 'react';

const TimedField = ({ value, onChange, as: Component }) => {
  const [localValue, setLocalValue] = React.useState(value);
  const [timerId, setTimerId] = React.useState(null);

  const handleChange = (newValue) => {
    if (timerId !== null) { clearTimeout(timerId); }
    setLocalValue(newValue);
    const timeoutId = setTimeout(() => onChange(newValue), 350);
    setTimerId(timeoutId);
  };

  return (
    <Component
      value={localValue}
      onChange={handleChange}
    />
  );
};

export default TimedField;
