import React from 'react';
import { Form } from 'semantic-ui-react';
import { ErrorMessage } from 'formik';
import FieldError from './FieldError';

const InputField = ({
  field: {
    name,
    value,
    onChange: handleChange,
    onBlur: handleBlur,
  },
  form: {
    touched,
    errors,
    isSubmitting,
  },
  as: AsComponent,
  label,
  ...props
}) => {
  const Component = AsComponent || Form.Input;
  const customHandleChange = ({ target: { name: ignoredName, ...rest } }, other) => {
    handleChange({ target: { name, ...rest } }, other);
  };
  const customHandleBlur = ({ target: { name: ignoredName, ...rest } }, other) => {
    handleBlur({ target: { name, ...rest } }, other);
  };

  return (
    <>
      <Component
        id={`id-${name}`}
        name={`wont-tell-the-name-${name}`}
        value={value}
        disabled={isSubmitting}
        error={errors[name] && touched[name]}
        onBlur={customHandleBlur}
        onChange={customHandleChange}
        label={label}
        {...props}
      />
      <ErrorMessage name={name} component={FieldError} />
    </>
  );
};

export default InputField;
