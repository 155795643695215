import React from 'react';
import { OrderedMap } from 'immutable';
import { withRouter } from 'react-router-dom';
import { Formik, Field as FormikField } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import {
  Form, Button, Grid, Checkbox, Header,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import getMessages from '../helpers/messages';
import Upload from '../helpers/components/Upload';
import InputField from '../helpers/components/InputField';
import QuantityField from '../helpers/components/QuantityField';
import Stepper from './Stepper';

const NewItemSchema = (messages) => Yup.object().shape({
  custom_product: Yup.string()
    .min(4, messages.FIELD_MIN_LENGTH(4))
    .required(messages.FIELD_REQUIRED),
  comment: Yup.string(),
  is_sealed: Yup.boolean(),
  quantity: Yup.number().min(1),
});

const ProductAddCustom = ({
  history: { push },
  match: { params },
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = React.useState(OrderedMap());

  const submitForm = (values) => {
    const payload = {
      quotation: parseInt(params.id, 10),
      custom_product: values.custom_product,
      quantity: values.quantity,
      comment: values.comment,
      is_sealed: values.is_sealed,
      images: files.valueSeq().toArray().filter((file) => file.uploaded).map((file) => file.id),
    };
    axios.post('/api/v1/quotation-items/', payload)
      .then(() => push(`/quotation/${params.id}/checkout`));
  };

  return (
    <Formik
      initialValues={{
        custom_product: '', comment: '', is_sealed: false, quantity: 1,
      }}
      validationSchema={NewItemSchema(getMessages(t))}
      onSubmit={submitForm}
      render={(props) => {
        const {
          errors, isSubmitting, handleSubmit,
        } = props;
        return (
          <Form
            onSubmit={handleSubmit}
            loading={isSubmitting}
            error={!!Object.keys(errors).length}
          >
            <Stepper step={2} />

            <Header as="h3">Round 2</Header>

            <Header as="h2">
              {t('productNotFoundTitle', "We don't have the product you are searching in our database. But not problems, you can register it manually:")}
            </Header>

            <FormikField
              component={InputField}
              name="custom_product"
              label={t('Product name')}
              placeholder={t('Product name here')}
            />

            <Form.Field>
              <label htmlFor="#quantity-input">{t('Quantity')}</label>
              <QuantityField
                id="quantity-input"
                value={props.values.quantity}
                onChange={(value) => props.setFieldValue('quantity', value)}
              />
            </Form.Field>

            <Form.Field>
              <Checkbox
                toggle
                label={t('Is this a new product, with sealed package?')}
                name="is_sealed"
                onBlur={props.onBlur}
                onChange={() => { props.setFieldValue('is_sealed', !props.values.is_sealed); }}
                checked={props.values.is_sealed}
              />
            </Form.Field>

            <Form.TextArea
              label={t('Do you want to insert a comment? (optional)')}
              name="comment"
              onBlur={props.onBlur}
              onChange={props.handleChange}
              value={props.values.comment}
            />

            <Upload
              label={t('Do you want to send a photo? (optional)')}
              quotation={params.id}
              files={files}
              setFiles={setFiles}
            />

            <Grid columns={2} stackable reversed="computer tablet" className="buttons-pair">
              <Grid.Column textAlign="right">
                <Button
                  id="next"
                  type="submit"
                  content={t('Next stage')}
                  primary
                  fluid
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Grid.Column>
              <Grid.Column textAlign="left">
                <Button
                  id="detail-close"
                  content={t('Back')}
                  type="button"
                  fluid
                  onClick={() => push(`/quotation/${params.id}`)}
                />
              </Grid.Column>
            </Grid>
          </Form>
        );
      }}
    />
  );
};

export default withRouter(ProductAddCustom);
