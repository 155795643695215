import React from 'react';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { Router, Switch, Route } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import ScrollToTop from './ScrollToTop';
import Start from './Start';
import QuotationCreate from './QuotationCreate';
import Footer from './Footer';
import HelpButton from './HelpButton';
import HelpModal from './HelpModal';
import TermsModal from './TermsModal';
import Acknowledgment from './Acknowledgment';
import ProductSearch from './ProductSearch';
import ProductAdd from './ProductAdd';
import ProductAddCustom from './ProductAddCustom';
import Checkout from './Checkout';
import QuotationUpdate from './QuotationUpdate';

const history = createBrowserHistory();

const App = ({ googleAnalyticsId }) => {
  const [selectedProduct, setSelectedProduct] = React.useState({});

  React.useEffect(() => {
    if (googleAnalyticsId) {
      ReactGA.initialize(googleAnalyticsId);
      history.listen((location) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      });
    }
  }, []);

  const store = {
    selectedProduct,
    setSelectedProduct,
  };

  return (
    <Router history={history}>
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path={['/', '/help', '/terms']}
        >
          <Container className="content">
            <Start />
          </Container>
        </Route>

        <Route
          exact
          path={[
            '/quotation',
            '/quotation/help',
            '/quotation/terms',
          ]}
        >
          <Container className="content">
            <QuotationCreate {...store} />
          </Container>
        </Route>

        <Route
          path="/quotation/:id(\d+)/thanks"
        >
          <Container className="content acknowledge">
            <Acknowledgment {...store} />
          </Container>
        </Route>

        <Route
          path="/quotation/:id(\d+)"
        >
          <Container className="content">
            <Switch>
              <Route
                exact
                path={[
                  '/quotation/:id(\\d+)',
                  '/quotation/:id(\\d+)/help',
                  '/quotation/:id(\\d+)/terms',
                ]}
              >
                <ProductSearch {...store} />
              </Route>

              <Route
                exact
                path={[
                  '/quotation/:id(\\d+)/update',
                  '/quotation/:id(\\d+)/update/help',
                  '/quotation/:id(\\d+)/update/terms',
                ]}
              >
                <QuotationUpdate />
              </Route>

              <Route
                path={[
                  '/quotation/:id(\\d+)/custom',
                  '/quotation/:id(\\d+)/custom/help',
                  '/quotation/:id(\\d+)/custom/terms',
                ]}
              >
                <ProductAddCustom {...store} />
              </Route>

              <Route
                path={[
                  '/quotation/:id(\\d+)/checkout',
                  '/quotation/:id(\\d+)/checkout/help',
                  '/quotation/:id(\\d+)/checkout/terms',
                ]}
              >
                <Checkout {...store} />
              </Route>

              <Route
                path={[
                  '/quotation/:id(\\d+)/:sku',
                  '/quotation/:id(\\d+)/:sku/help',
                  '/quotation/:id(\\d+)/:sku/terms',
                ]}
              >
                <ProductAdd {...store} />
              </Route>
            </Switch>
          </Container>
        </Route>
      </Switch>

      <Footer />

      <HelpButton />

      <Switch>
        <Route path="/:foo*/help">
          <HelpModal />
        </Route>

        <Route path="/:foo*/terms">
          <TermsModal />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
