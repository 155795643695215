import React from 'react';
import { Form } from 'semantic-ui-react';
import InputField from './InputField';

const SelectField = ({
  field: {
    name,
    value,
  },
  form: {
    setFieldValue,
    setFieldTouched,
    ...formProps
  },
  ...props
}) => (
  <InputField
    as={Form.Select}
    field={{
      name,
      value,

      // Replace handleChange and handleBlur to set field value and touched
      // explicitly. Without that, due semantic-ui construction, we won't be
      // telling which field to update
      onChange: (evt, { value: changedValue }) => setFieldValue(name, changedValue),
      onBlur: () => setFieldTouched(name),
    }}
    form={{ setFieldValue, setFieldTouched, ...formProps }}
    {...props}

  />
);

export default SelectField;
