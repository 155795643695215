/* eslint-disable max-len */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Header } from 'semantic-ui-react';

const Terms = ({
  history: { push },
  location: { pathname },
}) => {
  const [modalOpen, setModalOpen] = React.useState(true);

  const handleClose = () => setModalOpen(false);
  const handleUnmount = () => {
    const dest = pathname.replace('/terms', '');
    push(dest);
  };

  return (
    <Modal
      size="small"
      closeIcon
      open={modalOpen}
      onClose={handleClose}
      onUnmount={handleUnmount}
    >
      <Modal.Content>
        <Header as="h1">Termos de uso e política de privacidade</Header>
        <p>
          O MeuGameUsado tem o compromisso com a privacidade e segurança de seus clientes durante todo o processo de navegação pelo site. Os dados cadastrais como nome, telefone e e-mail nunca serão vendidos ou trocados e só serão divulgados para terceiros quando tais informações forem necessárias para coleta, entrega ou solicitação judicial.
        </p>

        <p>
          O MeuGameUsado utiliza cookies e informações de navegação (sessão do browser) com o objetivo de traçar um perfil do público que visita o site e aperfeiçoar sempre nossos serviços. Durante todo este processo mantemos as informações em sigilo absoluto. Os dados são registrados pelo MeuGameUsado de forma automatizada, dispensando manipulação humana.
        </p>

        <p>
          A partir do momento que você aceitar e dar início a cotação, poderemos fazer contatos com você via e-mail, visando a melhor experiência possível para sua navegação.
        </p>
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(Terms);
