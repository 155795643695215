import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import {
  Form,
  Search,
} from 'semantic-ui-react';
import axios from 'axios';

import ResultRenderer from './ResultRenderer';

class SearchField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      query: '',
      results: [],
      fullObjects: [],
      searchTimeout: null,
    };
  }

  updateResults = () => {
    const { query } = this.state;

    axios.get(`/api/v1/products/?search=${query}`)
      .then(({ data: { results: fullObjects } }) => {
        const results = fullObjects.map(
          (item, index) => ({ index, title: item.name, image: item.cover_image }),
        );

        results.push({
          title: (
            <Trans
              i18nKey="itemNotFound"
              defaults="You didn't find the product you are searching for? <0>Click here</0>."
              components={[<span>univers</span>]}
            />
          ),
          'data-custom-handler': true,
        });

        this.setState({
          isLoading: false,
          searchTimeout: null,
          fullObjects,
          results,
        });
      });
  };

  handleSearchChange = (evt, { value }) => {
    this.setState({ query: value });
    const { searchTimeout } = this.state;

    if (value.length > 2) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      const newSearchTimeout = setTimeout(this.updateResults, 750);
      this.setState({ searchTimeout: newSearchTimeout, isLoading: true });
    }
  };

  handleResultSelect = (
    evt,
    {
      result: {
        index,
        'data-custom-handler': isCustomHandler,
      },
    },
  ) => {
    const {
      setSelectedProduct,
      history: { push },
      match: { params },
    } = this.props;
    const { fullObjects } = this.state;
    this.setState({ query: '' });

    let result;
    if (isCustomHandler) {
      result = {};
    } else {
      result = fullObjects[index];
    }

    setSelectedProduct({
      ...result, image: result.cover_image, quantity: 1, quality_level: {}, comment: '',
    });

    if (isCustomHandler) {
      push(`/quotation/${params.id}/custom`);
    } else {
      push(`/quotation/${params.id}/${result.sku}`);
    }
  };

  render() {
    const { isLoading, query, results } = this.state;
    const { width, t } = this.props;
    return (
      <Form.Field
        control={Search}
        label={t('Which product do you want to sell?')}
        placeholder={t('Type the product name')}
        className="product-search"
        icon="custom-search"
        fluid
        width={width}
        loading={isLoading}
        value={query}
        results={results}
        onSearchChange={this.handleSearchChange}
        onResultSelect={this.handleResultSelect}
        resultRenderer={ResultRenderer}
      />
    );
  }
}

SearchField.propType = {
  width: PropTypes.number,
  setSelectedProduct: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

SearchField.defaultProps = {
  width: undefined,
};

const DecoratedSearchField = withRouter(withTranslation()(SearchField));

export default DecoratedSearchField;
