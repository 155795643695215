import React from 'react';
import { Step } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Stepper = ({ step: stepIndex }) => {
  const { t } = useTranslation();
  const steps = [
    t('Personal data'),
    t('Quotation'),
    t('Confirmation'),
  ];
  return (
    <Step.Group unstackable fluid>
      {steps.map((step, index) => (
        <Step
          key={step}
          active={index === (stepIndex - 1)}
          disabled={index > (stepIndex - 1)}
        >
          {step}
        </Step>
      ))}
    </Step.Group>
  );
};

export default Stepper;
