/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container, Modal, Button, Header,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

const HelpButton = ({
  history: { push },
  location: { pathname },
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);

  const handleClose = () => setModalOpen(false);
  const handleStartClick = () => {
    setRedirect(true);
    setModalOpen(false);
  };
  const handleUnmount = () => {
    if (redirect) {
      push('/quotation');
      setRedirect(false);
    } else {
      const dest = pathname.replace('/help', '');
      push(dest);
    }
  };

  return (
    <Modal
      size="small"
      closeIcon
      open={modalOpen}
      onClose={handleClose}
      onUnmount={handleUnmount}
    >
      <Modal.Content>
        <Header as="h1">{t('How does it works?')}</Header>
        <p>Se liga, fizemos um vídeo mostrando como é a maneira correta de fazer uma cotação de usados, assista com calma, fazendo com atenção e de acordo como mostramos no vídeo, sua cotação será entregue em 5 minutos!</p>
        <div className="iframe-wrapper">
          <iframe
            title={t('How does it works video')}
            src="https://player.vimeo.com/video/370895923?title=0&byline=0&portrait=0"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
          <script src="https://player.vimeo.com/api/player.js" />
        </div>
        <p>1 - Clique em &quot;Iniciar Cotação&quot;</p>
        <p>2 - Preencha seus dados como &quot;Nome, Email, Telefone, Cidade...&quot;</p>
        <p>Obs.: No campo &quot;cidade&quot;, ao digitar o nome, é importante clicar na sugestão que aparece, se apenas digitar o nome da cidade, você não conseguirá prosseguir com a cotação.</p>
        <p>3 - Na barra de buscas, você irá digitar o nome do jogo ou do produto que está interessado em cotar.</p>
        <p><strong>Dicas para cotações de jogos</strong></p>
        <p>Comece sempre com &quot;Jogo&quot; seguido do nome do produto, e separado por um traço a sua plataforma.</p>
        <p>Exemplo: Jogo Far Cry Primal - PS4</p>
        <p><strong>Dicas para cotações de consoles</strong></p>
        <p>Comece com &quot;Console&quot; depois informe o nome como Playstation 3, Playstation 4, Xbox One entre outros,seguido de seu modelo como, Slim, Super Slim, One S, One X entre outros, e por fim a sua memória 500GB, 1TB, 2TB e etc</p>
        <p>Exemplo: Console Xbox 360 Super Slim 250GB - Microsoft</p>
        <p>Para acessórios e outros, comece informando qual produto é, por exemplo, controle, Amiibo entre outros. Essas informações são muito importantes para que sua cotação seja respondida o mais rápido possível, quanto mais correto você fazer a cotação, mais rápido será a sua resposta.</p>

        <p>4 - Após selecionar o item correto, selecione as condições em que seu produto se encontra.</p>
        <p>5 - Se necessário, você pode anexar uma foto e descrição do produto.</p>
        <p>Obs.: Caso o produto e sua embalagem estejam em ótimo estado, essa etapa não será necessária.</p>
        <p>6 - Após o item adicionado na cotação, você pode repetir o processo com outros produtos caso necessário, ou seguir com a cotação.</p>
        <p>Obs.: É muito importante inserir seus produtos um por um, e caso o item não apareça automaticamente na busca, tente inserir uma parte do nome do jogo, por exemplo, ao digitar &quot;The Witcher 3&quot; e não encontrar, procure apenas por &quot;Witcher&quot;, se mesmo assim não encontrar o produto, você pode inseri-lo manualmente clicando em &quot;Não encontrou o produto desejado? Clique aqui&quot;</p>
        <p>7 - Após todos os produtos selecionados, basta clicar em &quot;Finalizar Cotação&quot;.</p>
        <p>8 - Finalizada a cotação, você receberá um número de protocolo, e só aguardar a sua cotação em seu e-mail.</p>
        <Container fluid textAlign="center">
          <Button
            color="green"
            type="button"
            className="fancy"
            onClick={handleStartClick}
          >
            {t('Start quotation')}
          </Button>
        </Container>
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(HelpButton);
