import React from 'react';
import { OrderedMap } from 'immutable';
import axios from 'axios';

export const useProductsAndQuotation = (quotationId) => {
  const [quotation, setQuotation] = React.useState(undefined);
  const [products, setProducts] = React.useState(undefined);

  React.useEffect(() => {
    axios.get(`/api/v1/quotations/${quotationId}/`)
      .then(({ data }) => {
        const items = new OrderedMap(data.items.map((item) => ([item.id, {
          id: item.id,
          comment: item.comment,
          images: item.images,
          quantity: item.quantity,
          quality_level: item.quality_level ? item.quality_level : null,
          name: item.product ? item.product.name : item.custom_product,
          image: item.product ? item.product.cover_image : null,
          is_sealed: item.is_sealed,
        }])));
        setProducts(items);
        setQuotation(data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          setQuotation(null);
          setProducts(null);
        }
      });
  }, [quotationId]);

  return {
    quotation, products, setQuotation, setProducts,
  };
};

export const useClientOrigins = () => {
  const [clientOrigins, setClientOrigins] = React.useState([]);

  React.useEffect(() => {
    axios.get('/api/v1/client-origins/')
      .then(({ data }) => {
        const options = data.map((obj) => ({
          key: obj.id,
          text: obj.name,
          value: obj.id,
        }));
        setClientOrigins(options);
      });
  }, []);

  return { clientOrigins };
};
