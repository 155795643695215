import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Grid, Container, Header, Image, Icon, Button, Loader,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useProductsAndQuotation } from '../helpers/hooks';
import winner from '../../img/winner.png';

const Acknowledgment = ({
  history: { push },
  match: { params },
}) => {
  const { t } = useTranslation();
  const { products, quotation } = useProductsAndQuotation(params.id);

  if (products === null) {
    push('/');
  }

  if (quotation && !quotation.is_done) {
    push(`/quotation/${quotation.id}/checkout`);
  }

  if (!products || (quotation && !quotation.is_done)) {
    return <Loader active inline="centered" />;
  }

  return (
    <Grid centered>
      <Grid.Column>
        <Container textAlign="center">
          <Image src={winner} size="small" centered alt="Moeda" className="acknowledge-header" />
          <Header as="h1" className="compact">
            {t('YOU WIN!!!')}
          </Header>

          <Header as="h1" className="compact">{t('You quotation request was finished!')}</Header>

          <p>{t('Soon we will send you an email with all details about your sell!')}</p>

          <p>{t('Pay attention to your spam and mass mail folder!')}</p>
        </Container>

        <Container>
          <div className="quotation-resume">
            <header>
              <div className="title">{t('Quotation number')}</div>
              <div className="number">{`#${params.id}`}</div>
            </header>
            <div className="body">
              <table>
                <thead>
                  <tr>
                    <th>{t('Product')}</th>
                    <th>{t('Qty')}</th>
                  </tr>
                </thead>
                <tbody>
                  {products.valueSeq().toArray().map((product) => (
                    <tr key={product.id}>
                      <td>{product.name}</td>
                      <td>{`x${product.quantity}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Container>

        <Container textAlign="center">
          <p>{t('Until that, checkout our social networks')}</p>
          <div className="acknowledge-social-networks">
            <a
              href="https://www.instagram.com/meugameusado/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="instagram" />
            </a>
            <a
              href="https://www.youtube.com/user/SiteShopB"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="youtube" />
            </a>
          </div>
          <Button
            as="a"
            href="http://meugameusado.com.br"
            color="green"
            className="fancy"
            id="back-to-site"
          >
            {t('Back to the website!')}
          </Button>
        </Container>
      </Grid.Column>
    </Grid>
  );
};

export default withRouter(Acknowledgment);
