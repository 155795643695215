import React from 'react';
import {
  Segment, Image, Grid, Header, Button,
} from 'semantic-ui-react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import emptyBox from '../../img/empty-box.svg';
import gameManuallyAdded from '../../img/game-manually-added.svg';
import whiteImage from '../../img/white-image.png';
import TimedField from '../helpers/components/TimedField';
import QuantityField from '../helpers/components/QuantityField';

const ProductList = ({
  products,
  setProducts,
}) => {
  const { t } = useTranslation();

  if (products.size === 0) {
    return (
      <Segment className="empty-product-list" basic textAlign="center">
        <Image
          centered
          src={emptyBox}
          size="medium"
        />
        <h3>{t('Your quotation is empty')}</h3>
      </Segment>
    );
  }

  return (
    <Segment className="product-items" inverted>
      <div>
        {products.valueSeq().toArray().map((product) => (
          <Segment key={product.id}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={6} tablet={5} computer={4}>
                  <Image src={product.image || gameManuallyAdded} size="large" />
                </Grid.Column>
                <Grid.Column verticalAlign="middle" mobile={10} tablet={11} computer={12}>
                  <Header as="h4">{product.name}</Header>
                  <TimedField
                    as={QuantityField}
                    value={product.quantity}
                    onChange={(value) => {
                      axios.patch(`/api/v1/quotation-items/${product.id}/`, { quantity: value }).then(
                        () => setProducts(
                          (items) => items.update(
                            product.id, (obj) => ({ ...obj, quantity: value }),
                          ),
                        ),
                      );
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  {product.quality_level && (
                    <div className="product-item-term">
                      <strong>{t('Product conditions')}</strong>
                      {product.quality_level.attribute.name}
                    </div>
                  )}
                  {!product.quality_level && (
                    <div className="product-item-term">
                      <strong>{t('Is this a new product, with sealed package?')}</strong>
                      {product.is_sealed ? t('Yes') : t('No')}
                    </div>
                  )}
                  {product.comment && (
                    <div className="product-item-term">
                      <strong>{t('Comment')}</strong>
                      {product.comment}
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row verticalAlign="bottom">
                <Grid.Column mobile={8} tablet={10} computer={8}>
                  <strong className="product-item-images-title">{t('Images')}</strong>
                  <Image.Group size="mini">
                    {product.images.length === 0 && (
                      <Image src={whiteImage} rounded bordered />
                    )}
                    {product.images.map((image) => (
                      <Image key={image.file} src={image.file} rounded />
                    ))}
                  </Image.Group>
                </Grid.Column>
                <Grid.Column className="product-item-actions" mobile={6} tablet={6} computer={6} floated="right" textAlign="right">
                  <Button
                    type="button"
                    icon="trash alternate outline"
                    color="red"
                    onClick={() => {
                      axios.delete(`/api/v1/quotation-items/${product.id}/`).then(
                        () => setProducts((items) => items.delete(product.id)),
                      );
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ))}
      </div>
    </Segment>
  );
};

export default ProductList;
