import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Header, Button } from 'semantic-ui-react';
import HelpButton from './HelpButton';

const Start = ({ history: { push } }) => {
  const { t } = useTranslation();
  return (
    <Container fluid textAlign="center">
      <Header as="h1">
        Que tal dar um reset em
        {' '}
        <br />
        {' '}
        seus games zerados e
        {' '}
        <br />
        {' '}
        passá-los para um player 2?
      </Header>
      <p>
        Venda os seus jogos esquecidos no armário para o
        {' '}
        <br />
        {' '}
        Meu Game Usado e receba em dinheiro ou créditos
        {' '}
        <br />
        {' '}
        para gastar em nossa loja!
      </p>

      <Button
        color="green"
        className="fancy"
        id="start-quotation"
        onClick={() => push('/quotation')}
      >
        {t('Start quotation')}
      </Button>

      <HelpButton>{t('How does it works?')}</HelpButton>
    </Container>
  );
};

export default withRouter(Start);
