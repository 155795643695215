import React from 'react';
import { Image, Progress } from 'semantic-ui-react';
import whiteImage from '../../../../img/white-image.png';

const Item = ({ file, onClick: handleClick }) => {
  if (!file.uploaded && !file.error) {
    return (
      <Image>
        <Progress percent={file.progress} indicating />
      </Image>
    );
  }

  if (file.error) {
    return (
      <Image
        fluid
        size="tiny"
        label={{
          as: 'a', color: 'orange', corner: 'right', icon: 'warning',
        }}
        src={whiteImage}
      />
    );
  }

  return (
    <Image
      rounded
      label={{
        color: 'red',
        floating: true,
        icon: 'remove',
      }}
      src={file.preview}
      onClick={() => handleClick(file)}
    />
  );
};

export default Item;
