import React from 'react';
import { withRouter } from 'react-router-dom';
import path from 'path';

const Terms = ({
  history: { push },
  location: { pathname },
}) => {
  const handleTriggerClick = () => {
    push(path.join(pathname, '/terms'));
  };

  return (
    <button
      type="button"
      onClick={handleTriggerClick}
    >
      Termos de uso e política de privacidade.
    </button>
  );
};

export default withRouter(Terms);
