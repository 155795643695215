import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import {
  Grid, Button, Header, Loader,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useProductsAndQuotation } from '../helpers/hooks';
import ProductList from './ProductList';
import Stepper from './Stepper';

const Checkout = ({
  history: { push },
  match: { params },
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { products, setProducts } = useProductsAndQuotation(params.id);

  if (products === null) {
    push('/');
  }

  if (products && products.size === 0) {
    push(`/quotation/${params.id}`);
  }

  if (!products || products.size === 0) {
    return <Loader active inline="centered" />;
  }

  const submitCheckout = () => {
    setIsSubmitting(true);
    axios.patch(`/api/v1/quotations/${params.id}/`, { is_done: true })
      .then(() => {
        setIsSubmitting(false);
        push(`/quotation/${params.id}/thanks`);
      });
  };

  return (
    <>
      <Stepper step={3} />

      <Header as="h3">{t('Round 3')}</Header>

      <Header as="h2">
        {t('checkoutInstructions', 'Quase tudo pronto! Agora só falta conferir se todas as informações estão corretas!')}
      </Header>

      <ProductList products={products} setProducts={setProducts} />

      <Button
        id="add-another"
        content={t('+ Add new product')}
        className="add-another"
        primary
        fluid
        disabled={isSubmitting}
        onClick={() => push(`/quotation/${params.id}`)}
      />

      <Grid className="buttons-pair" columns={2} stackable reversed="computer tablet">
        <Grid.Column>
          <Button
            id="finish"
            content={t('Finish quotation')}
            fluid
            color="green"
            loading={isSubmitting}
            disabled={products.size === 0 || isSubmitting}
            onClick={submitCheckout}
          />
        </Grid.Column>
        <Grid.Column>
          <Button
            id="back"
            type="button"
            content={t('Back')}
            secondary
            fluid
            disabled={isSubmitting}
            onClick={() => push(`/quotation/${params.id}`)}
          />
        </Grid.Column>
      </Grid>
    </>
  );
};

export default withRouter(Checkout);
