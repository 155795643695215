import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Form, Search } from 'semantic-ui-react';
import { ErrorMessage } from 'formik';
import FieldError from '../helpers/components/FieldError';


class CitySearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      query: '',
      results: [],
      selectedResult: null,
      searchTimeout: null,
    };
  }

  componentDidMount() {
    const { field: { value } } = this.props;
    if (value) {
      this.setState({ isLoading: true });
      axios.get(`/api/v1/cities/${value}/`)
        .then(({ data }) => this.setState({
          isLoading: false,
          query: data.name,
        }));
    }
  }

  handleSearchChange = (evt, { value }) => {
    const { searchTimeout } = this.state;
    const { field: { name, onChange } } = this.props;
    this.setState({ query: value });
    onChange({ target: { name, value: '' } });

    if (value.length > 2) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      const newSearchTimeout = setTimeout(this.updateResults, 350);
      this.setState({ searchTimeout: newSearchTimeout, isLoading: true });
    }
  }

  updateResults = () => {
    const { query } = this.state;
    axios.get(`/api/v1/cities/?q=${query}`)
      .then(({ data: { results } }) => this.setState({
        isLoading: false,
        searchTimeout: null,
        results: results.map((item) => ({ id: item.id, title: item.name })),
      }));
  }

  handleResultSelect = (evt, { result }) => {
    const { field: { name, onChange } } = this.props;
    this.setState({ query: result.title });
    onChange({ target: { name, value: result.id } });
  }

  handleSelectionChange = (evt, { result }) => {
    this.setState({ selectedResult: result });
  }

  handleKeyDown = (event) => {
    const { field: { name, onChange } } = this.props;
    const { results, selectedResult } = this.state;
    if (event.key === 'Enter') {
      event.preventDefault();
    }

    if (event.key === 'Enter' || event.key === 'Tab') {
      let result;
      if (selectedResult) {
        result = selectedResult;
      } else if (results.length > 0) {
        [result] = results;
      }

      if (result) {
        this.setState({ query: result.title });
        onChange({ target: { name, value: result.id } });
      }
    }
  }

  handleBlur = () => {
    const { field: { name, onBlur } } = this.props;
    onBlur({ target: { name } });
  }

  render() {
    const {
      field: { name },
      form: { errors, touched },
      label,
      placeholder,
    } = this.props;
    const { results, isLoading, query } = this.state;
    return (
      <>
        <Form.Field
          control={Search}
          label={label}
          placeholder={placeholder}
          fluid
          loading={isLoading}
          input="search"
          icon="custom-search"
          name="wont-tell-the-name-search-field"
          value={query}
          results={results}
          error={errors[name] && touched[name]}
          onSearchChange={this.handleSearchChange}
          onResultSelect={this.handleResultSelect}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          onSelectionChange={this.handleSelectionChange}
        />
        <ErrorMessage name={name} component={FieldError} />
      </>
    );
  }
}

CitySearch.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default CitySearch;
