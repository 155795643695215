import React from 'react';
import { Form, Radio } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const QualityLevel = ({ items, setSelectedProduct }) => {
  const [level, setLevel] = React.useState(null);
  const { t } = useTranslation();

  if (items === undefined) {
    return null;
  }

  return (
    <Form.Group grouped>
      <label htmlFor="qualityLevel">{t('Product conditions')}</label>

      {items.map((item) => (
        <Form.Field key={item.id}>
          <Radio
            name="qualityLevel"
            checked={level === item.id}
            onChange={(e, { value }) => {
              setLevel(value);
              setSelectedProduct((product) => ({ ...product, quality_level: item }));
            }}
            label={item.attribute.name}
            value={item.id}
          />
        </Form.Field>
      ))}
    </Form.Group>
  );
};

export default QualityLevel;
