import React from 'react';
import {
  Form,
} from 'semantic-ui-react';

const Textarea = ({
  label, name, text, onChange,
}) => (
  <Form.Field>
    <Form.TextArea
      label={label}
      name={name}
      onChange={(e, { value }) => onChange(value)}
      value={text}
    />
  </Form.Field>
);

export default Textarea;
