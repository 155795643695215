import React from 'react';
import { Form, Button, Input } from 'semantic-ui-react';

const QuantityField = ({ value, onChange, ...props }) => (
  <Form.Input className="quantity-button" action>
    <Button
      type="button"
      size="mini"
      onClick={() => onChange(value - 1)}
      className="decrement"
    >
    -
    </Button>
    <Input
      name="quantity"
      type="number"
      min="1"
      id="quantity-input"
      value={value}
      onChange={(evt, { value: newValue }) => onChange(newValue)}
      {...props}
    />
    <Button
      type="button"
      size="mini"
      onClick={() => onChange(value + 1)}
      className="increment"
    >
    +
    </Button>
  </Form.Input>
);

export default QuantityField;
