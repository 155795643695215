import React from 'react';
import { Form, Image } from 'semantic-ui-react';
import { OrderedMap } from 'immutable';
import uuid from 'uuidv4';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import whiteImage from '../../../../img/white-image.png';
import Item from './Item';

const getFilesMap = (acceptedFiles) => (
  OrderedMap(acceptedFiles.map((file) => {
    const id = uuid();
    const data = {
      file,
      id,
      fileId: id,
      name: file.name.toLowerCase(),
      readableSize: file.size,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    };
    return [id, data];
  })));

const getPayloadData = (quotation, file) => {
  const data = new FormData();
  data.append('file', file.file, file.name);
  data.append('quotation', quotation);
  return data;
};

const calculateProgress = (evt) => (
  parseInt(Math.round((evt.loaded * 100) / evt.total), 10));

const Upload = ({
  label,
  quotation,
  files: allFiles,
  setFiles,
}) => {
  const { t } = useTranslation();
  const onDropAccepted = React.useCallback((acceptedFiles) => {
    const uploadedFiles = getFilesMap(acceptedFiles);

    setFiles((state) => state.merge(uploadedFiles));

    // post files to the server
    uploadedFiles.forEach((uploadedFile) => {
      const onUploadProgress = (evt) => setFiles((files) => files.update(
        uploadedFile.id, (state) => ({ ...state, progress: calculateProgress(evt) }),
      ));

      const onUploadDone = ({ data }) => (
        setFiles(
          (files) => files.update(
            uploadedFile.id, (state) => ({
              ...state,
              uploaded: true,
              id: data.id,
              url: data.file,
            }),
          ),
        ));

      const onUploadError = () => (
        setFiles(
          (files) => files.update(
            uploadedFile.id, (state) => ({
              ...state,
              error: true,
            }),
          ),
        ));

      axios.post(
        '/api/v1/quotation-images/',
        getPayloadData(quotation, uploadedFile),
        { onUploadProgress },
      )
        .then(onUploadDone)
        .catch(onUploadError);
    });
  });

  const handleRemove = ({ id, fileId }) => {
    axios.delete(`/api/v1/quotation-images/${id}/`)
      .then(() => setFiles((files) => files.delete(fileId)));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDropAccepted,
  });

  const limit = 8;

  return (
    <Form.Field>
      <label htmlFor="upload-image">{label}</label>

      {allFiles.size >= limit && (
        <div className="form-error">{t('You hit the limit of {{limit}} images', { limit })}</div>
      )}

      <Image.Group size="tiny">
        {allFiles.valueSeq().map((file) => (
          <Item
            key={file.id}
            file={file}
            onClick={handleRemove}
          />
        ))}

        {allFiles.size < limit && (
          <span>
            <Image src={whiteImage} bordered {...getRootProps()} />
            <p>{t('Click in the image to add')}</p>
            <input id="upload-image" {...getInputProps()} />
          </span>
        )}
      </Image.Group>
    </Form.Field>
  );
};

export default Upload;
