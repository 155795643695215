import React from 'react';
import { Container } from 'semantic-ui-react';
import Terms from './Terms';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Container className="footer">
      © Meu Game Usado
      {' '}
      {year}
      {' '}
      -
      {' '}
      <br />
      {' '}
      Todos os direitos reservados.
      {' '}
      <br />
      {' '}
      <Terms />
    </Container>
  );
};

export default Footer;
